.body-container {
    > div {
        margin-bottom: 10px;    
    }
}

.trigger-container {
    display: flex;
    align-items: center;
    gap: 30px;
}