.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .loginForm {
    width: 400px;
    background-color: #ffffff70;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .inputField,
  .pwdInputField {
    margin-bottom: 15px;
    width: 100%;

    display: flex;
  }

  .pwdInputField {
    margin-bottom: 2px;
  }
  
  .input {
    padding: 10px;
    border-radius: 5px;
    background-color: #ffffff70;
    border: 1px solid #ccc;
    flex-grow: 1;

    &:focus-visible {
      outline: none;
    }
  }
  
  .button {
    width: 100%;
    padding: 10px;
    background-color: #df9704;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    margin-bottom: 20px;
  
    &:hover {
      background-color: #ac7402;
    }
  }
  
  .socialButtons {
    display: flex;
    justify-content: space-between;
  }
  
  .socialButton {
    width:48%;
  }

  .forgot-pwd {
    text-decoration: underline;
    text-align: end;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .sign-up-note-question {
    margin-right: 5px;
  }

  .sign-up-note-answer {
    text-decoration: underline;   
    cursor: pointer;
  }