@keyframes slideInFromLeft {
    0% {
        opacity: 0; /* Fully transparent */
        transform: translateX(-100%); /* Start off-screen */
    }
    100% {
        opacity: 1; /* Fully visible */
        transform: translateX(0); /* End at original position */
    }
}

@keyframes slideInFromRight {
    0% {
        opacity: 0; /* Fully transparent */
        transform: translateX(+100%); /* Start off-screen */
    }
    100% {
        opacity: 1; /* Fully visible */
        transform: translateX(0); /* End at original position */
    }
}

@keyframes slideInFromTop {
    0% {
        opacity: 0; /* Fully transparent */
        transform: translateY(-300%); /* Start off-screen */
    }
    100% {
        opacity: 1; /* Fully visible */
        transform: translateY(0); /* End at original position */
    }
}

@keyframes slideInFromBottom {
    0% {
        opacity: 0; /* Fully transparent */
        transform: translateY(300%); /* Start off-screen */
    }
    100% {
        opacity: 1; /* Fully visible */
        transform: translateY(0); /* End at original position */
    }
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-7px); }
    50% { transform: translateX(7px); }
    60% { transform: translateX(-7px); }
    75% { transform: translateX(7px); }
    90% { transform: translateX(-7px); }
    100% { transform: translateX(0); }
}
