@import "../animations.scss";

.page {
    width: 100%;

    text-align: center;

    margin-bottom: 50px;

    .container {
        gap: 30px;
        padding: 20px;


        @media (min-width: 769px) {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .horizontal-ads {
            width:  calc(100vw - 80px);
            height: fit-content;
            background-color: white;
            border: 1px solid #ccc;
            position: relative;
            overflow: hidden;
            padding: 20px;
            z-index: 0;
            margin: auto;

            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;

            margin-bottom: 20px;

            cursor: pointer;

            @media (max-width: 426px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0;

                > * {
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            // &::before {
            //     content: " ";
            //     background: linear-gradient(to bottom right, #103a63 50%, #ffffff00 50%);
            //     width: 100px;
            //     height: 100%;
            //     position: absolute;
            //     left: 0;
            //     z-index: 1;
            // }

            @media (min-width: 426px) {
                &::after {
                    content: " ";
                    background: linear-gradient(to bottom right, #ffffff00 50%, #103a63 50%);
                    width: 200px;
                    height: 100%;
                    position: absolute;
                    right: 0;
                    z-index: 1;
                }
            }

            .ads-header,
            .ads-msg,
            .ads-action,
            .ads-logo {
                z-index: 2;
            }

            .ads-logo {
                width: 115px;

                &.animation {
                    transform: translateY(-300%);
                    animation: slideInFromTop 1s forwards;
                }
            }

            .ads-text-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }

            .ads-header {
                font-size: 30px;
                font-weight: bold;
                color: black;

                margin-bottom: 5px;

                &.animation {
                    transform: translateX(+100%);
                    animation: slideInFromRight 1s forwards;
                }
            }

            .ads-msg {
                max-width: 250px;

                &.animation {
                    opacity: 0;
                    transform: translateX(-100%);
                    animation: slideInFromLeft 1s forwards;
                }
            }

            .ads-action {
                padding: 5px 20px;
                color: white;
                font-weight: bold;
                background-color: #1cbcff;
                box-shadow: -1px 2px 4px 1px #00000040;

                &.animation {
                    transform: translateY(300%);
                    animation:
                        slideInFromBottom 1s forwards,
                        shake 0.7s 5s;
                }
            }

            @media (min-width: 769px) {
                display: none;
            }
        }

        .vertical-ads {
            width: 250px;
            height: 95vh;
            max-height: 95vh;
            background-color: white;
            border: 1px solid #ccc;
            position: relative;
            overflow: hidden;
            padding: 0 20px;
            z-index: 0;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            cursor: pointer;

            &::before {
                content: " ";
                background: linear-gradient(to bottom left, #103a63 50%, #ffffff00 50%);
                width: 200%;
                height: calc(50% - 150px);
                position: absolute;
                top: 0;
                z-index: 1;
            }

            &::after {
                content: " ";
                background: linear-gradient(to bottom left, #ffffff00 50%, #103a63 50%);
                width: 200%;
                height: calc(50% - 150px);
                position: absolute;
                bottom: 0;
                z-index: 1;
            }

            .ads-header,
            .ads-msg,
            .ads-action,
            .ads-logo {
                z-index: 2;
            }

            .ads-logo {
                width: 115px;
                margin-bottom: 40px;

                &.animation {
                    transform: translateY(-300%);
                    animation: slideInFromTop 1s forwards;
                }
            }

            .ads-header {
                font-size: 30px;
                font-weight: bold;
                color: black;

                margin-bottom: 5px;

                &.animation {
                    transform: translateX(+100%);
                    animation: slideInFromRight 1s forwards;
                }
            }

            .ads-msg {
                margin-bottom: 40px;

                &.animation {
                    opacity: 0;
                    transform: translateX(-100%);
                    animation: slideInFromLeft 1s forwards;
                }
            }

            .ads-action {
                padding: 5px 20px;
                color: white;
                font-weight: bold;
                background-color: #1cbcff;
                box-shadow: -1px 2px 4px 1px #00000040;

                &.animation {
                    transform: translateY(300%);
                    animation:
                        slideInFromBottom 1s forwards,
                        shake 0.7s 5s;
                }
            }

            @media (max-width: 768px) {
                display: none;
            }
        }
    }
}
