.container {
  position: relative;

  .input {
    padding: 10px;
    border-radius: 5px;
    background-color: #ffffff70;
    border: 1px solid #ccc;
    flex-grow: 1;
    width: 150px;

    &:focus-visible {
      outline: none;
    }

    &.info-present {
      padding-right: 30px;
    }
  }

  .info {
    width: 20px;
    position: absolute;
    top: 8px;
    right: 5px;
    filter: opacity(0.5);
    cursor: pointer;
  }

  .tooltip {
    background-color: rgb(61, 61, 61);
    background-color: white;
    border-radius: 15px;
    opacity: 0.5;
    text-align: left;
    z-index: 2;
    color: black;
    padding: 20px;
  }
}
