.container {

}

.control {
    border-radius: 5px !important;
    background-color: rgba(255, 255, 255, 0.4392156863) !important;
    border: 1px solid #ccc !important;
    width: 393px;
}

.menu-list {
    background-color: #C2D8F8 !important;

}

.menu {
    background-color: #C2D8F8 !important;
    cursor: pointer;
}

.option {
    background-color: #C2D8F8 !important;
    cursor: pointer !important;

    &:active {
        background-color: #91bdff !important;
    }

    &:focus-visible {
        outline: none !important;
    }

    &:hover {
        background-color: #91bdff !important;
    }

    &.first-ele-excluded {
        &:first-child {
            background-color: transparent !important;
            color: black !important;
            font-weight: bold;

            &:hover {
                background-color: transparent !important;
            }
        }
    }
}

.dropdown-indicator {
    color: grey !important;
}

.multi-value {
    background-color: #6ca7ff !important;

    > div {
    color: white;
    }
}

.id {
    
}