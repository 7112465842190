.container {
    .bg {
        background-color: #ffffff80;
        border-radius: 10px;
        margin: auto;
        width: fit-content;

        .table-actions-container {    
            margin-bottom: 5px;
    
            padding: 10px;
    
            max-width: calc(100vw - 40px);
    
            .table-actions {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: space-between;
    
                .filter-group {
                    border: 1px dashed grey;
                    margin: 5px;
                    padding: 5px;
    
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
    
                    .header {
                        font-weight: bold;
                    }
    
                    .filter-contianer {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: wrap;
    
                        > div {
                            padding: 5px;
                        }
                    }
                }
            }
        }
    
        .table-container {
            overflow-x: scroll;
            max-width: calc(100vw - 40px);
            max-height: 90vh;
            overflow: "auto";
            position: relative;
            margin: auto;
    
            background-color: #ffffff80;
    
            .table {
                display: grid;
                border: 1px solid lightgray;
    
                width: 100% !important;
    
                border-spacing: 0px;
    
                margin-bottom: 20px;
    
                .thead {
                    display: grid;
                    position: sticky;
                    top: 0;
                    z-index: 2;
    
                    background-color: #4e6a89;
    
                    color: white;
                    font-weight: bold;
    
                    cursor: pointer;
    
                    .header-row {
                        display: flex;
                        width: 100%;
    
                        .th {
                            border-bottom: 1px solid lightgray;
                            border-right: 1px solid lightgray;
                            background-color: #4e6a89;
                        }
                    }
                }
    
                .tbody {
                    display: grid;
                    position: relative;
                    border-bottom: 1px solid lightgray;
    
                    .rows {    
                        .cell {
                            border-bottom: 1px solid lightgray;
                            background-color: #cddff9;
    
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            overflow-wrap: anywhere;
                        }
    
                        .subrow-headers-wrapper {
                            flex: 1;
    
                            .subrow-headers {
                                background-color: #4e6a89;
    
                                color: white;
                                font-weight: bold;
                            }
                        }
    
                        .subrow-cell {
                            background-color: #e7f0fc;
                            border-bottom: 1px solid rgba(128, 128, 128, 0.484);
    
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        &:hover {
                            background-color: #a8ccff;

                            .cell {
                                background-color: #a8ccff;
                            }
                        }
                    }
                }
            }
        }
    }
}

// not using currently
.resizer.isResizing {
    background: blue;
    opacity: 1;
    width: 2px;
}
