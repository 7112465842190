.export-button {
    cursor: pointer;
    width: fit-content;

    img {
        width: 15px;
        margin-right: 5px;
    }
}

.filter-group {
    border: 1.5px dashed grey;
    margin: 5px;
    padding: 5px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .header {
        font-weight: bold;
    }

    .filter-contianer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        > div {
            padding: 5px;
        }
    }
}

.trigger {
}

.all-chips-container {
    gap: 10px;
    display: flex;
    align-items: center;

    .chips-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;

        .chip-container {
            text-align: left;
            border: 1.5px dashed darkgrey;
            padding: 10px;
    
            .chip-header {
                margin-bottom: 5px;
            }
    
            .chip-group {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;
    
                .chip {
                    border-radius: 10px;
                    background-color: #5ea1ff;
                    color: white;
                    padding: 5px 20px;
                    padding-right: 10px;
                    cursor: pointer;
            
                    .chip-close {
                        font-weight: bold;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .clear {
        cursor: pointer;
        opacity: 0.5;
    }
}