.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 30px;

  .logo {
    height: 50px;
    cursor: pointer;
  }

  .right-container {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    .marketplace {
      background-color: none !important;
      background: none !important;
      border: none !important;
      box-shadow: none !important;
      cursor: pointer;

      font-weight: bold;

      .single-value {
        font-weight:  bold;
        color: black;
      }

      .marketplace-dropdown-indicator {
        opacity: 0;
      } 

      .indicator-separator {
        opacity: 0;
      }
    }

    .menu {
      height: fit-content;
      max-height: 70vh;
      z-index: 3;
    }

    .menu-list {
      max-height: 70vh !important;
      background-color: #C2D8F8 !important;
    }
    
    .link {
      padding: 10px 30px;
      color: black;
      font-weight: bold;
      text-decoration: none;
    }

    .action-button {
      border-radius: 10px;
      background-color: #df9704;
      padding: 5px 10px;
      color: white;
      font-weight: bold;
      cursor: pointer;

      width: fit-content;

      text-decoration: none;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 20px;

      padding: 10px 36px;

      img {
        margin-right: 10px;
        width: 26px;
        filter: invert(1);
      }
    }
  }
}
