.container {
    text-align: center;

    .hero-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 3.875rem;
            line-height: 4.25rem;
            font-weight: 400;
    
            b {
                font-weight: bold;
            }
        }
    
        .description-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
    
            p {
                font-size: 1.25rem;
                line-height: 1.875rem;
    
                max-width: 90vw;
            }
        }
    
        .action-button {
            border-radius: 10px;
            background-color: #df9704;
            padding: 5px 10px;
            color: white;
            font-weight: bold;
            cursor: pointer;
    
            width: fit-content;
            height: 60px;
    
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;

    
            margin-bottom: 70px;
    
            font-size: 20px;
    
            padding: 8px 36px;
    
            img {
                margin-right: 10px;
                width: 26px;
                filter: invert(1);
            }
        }
    
        .screenshot {
            width: 90vw;
            box-shadow: 5px 5px 5px 5px #00000060;
    
            margin-bottom: 80px;
        }
    }

    .highlights-wrapper {
        background-color: white;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            font-size: 40px;
            margin-bottom: 0;
        }

        .highlight {
            display: flex;
            align-items: center;
    
            img {
                max-width: 350px;
            }
    
    
            .highlight-description {
                max-width: 500px;
    
                h2 {
                    font-size: 25px;
                }
    
                p {
                }
            }
        }   
    }
    

    .call-to-action-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 30px;

        h2 {
            font-size: 40px;
            font-weight: 400;

            color: white;

            b {
                font-weight: bold;
            }
        }
        .action-button {
            border-radius: 10px;
            background-color: #df9704;
            padding: 5px 10px;
            color: white;
            font-weight: bold;
            cursor: pointer;
    
            width: fit-content;
    
            display: flex;
            align-items: center;
            justify-content: center;

            text-decoration: none;
    
            margin-bottom: 70px;
    
            font-size: 20px;
    
            padding: 8px 36px;
    
            img {
                margin-right: 10px;
                width: 26px;
                filter: invert(1);
            }
        }
    }
}
